<template>
  <div>
    <b-table :items="languages" :fields="fields" responsive="sm">
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(flag)="data">
        <b-img v-if="data.value" :src="data.value" height="50" />
      </template>

      <template #cell(action)="data">
        <b-button
          @click="editLanguage(data.item)"
          size="sm"
          style="margin-right: 5px"
          variant="primary"
        >
          Edit
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="confirmDelete(data.item._id)"
        >
          Delete
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import service from "../../service";
export default {
  props: {
    languages: { type: Array, required: true },
  },
  data() {
    return {
      fields: ["stt", "flag", "name", "code", { key: "action", label: "" }],
    };
  },
  methods: {
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteLanguage(id);
          }
        });
    },
    async deleteLanguage(id) {
      await service.delete({ id: id });
      this.$store.dispatch("language/setLanguage");
      this.$emit("getList");
    },
    editLanguage(languageInfo) {
      this.$emit("edit", languageInfo);
    },
  },
};
</script>
